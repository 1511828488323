import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { Grid, Container, Typography, Box } from "@material-ui/core"
import "../styles/general.css"
import Footer from "./Footer"
import ImgUnisanmartinH from "../components/imgComponents/imgUnisanmartinH"
import ImgUnisabanaH from "../components/imgComponents/imgUnisabanaH"

const HomeComponent = () => {
  const { t, i18n } = useTranslation()
  return (
    <div className="componentsDiv">
      <Box align="justify" style={{ whiteSpace: "pre-wrap" }}>
        <h1 className="titlesGradientH1">{t("title.profile")}</h1>
        <Typography paragraph>{t("text.profile1")}</Typography>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
        >
          <Box m={1}>
            <ImgUnisanmartinH />
          </Box>
          <Box m={1}>
            <ImgUnisabanaH />
          </Box>
        </Grid>
      </Box>
      <br />
      <Box>
        <Footer />
      </Box>
    </div>
  )
}

export default HomeComponent
